
.pt-120 {
    padding-top: 120px;
}

.articles-row {
    /* --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0; */
    gap: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.article-col{
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
}