/* .news_feed {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 50px;
} */

.news-slider-container {
    padding-top: 50px;
    width: 100%;
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;
    padding: 20px;
    /* overflow: hidden; */
}

.news-slider-item {
    padding: 10px;
}

.news-container {
    height: 800px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    z-index: -1;
}

.news-container::before {
    position: absolute;
    height: 800px;
    width: 100%;
    content: "";
    background-color: rgba(52, 54, 56, 0.96);
}

.news-container-content {
    position: relative;
    z-index: 100;
}

.news-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.news-title h2 {
    font-size: 50px;
    color: white;
}

.news-button {
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.news-button-style {
    background-color: #f44336 !important;
}

.news-button-style:hover {
    background-color: red !important;
    color:white !important
}

@media (max-width:768px) {

    .news_feed {
        flex-direction: column;
        align-items: normal;
    }

    .news-container {
        height: 2700px;
    }

    .news-container::before{
        height: 2700px;
    }
}